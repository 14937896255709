/*
  https://stackoverflow.com/questions/47607602/how-to-add-a-tailwind-css-rule-to-css-checker
  regarding the linting issues in this file
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-stable {
  scrollbar-gutter: stable;
}

/**
  * Custom Tailwind class CSS
**/

.label-underline {
  @apply my-2 block text-left text-sm font-bold text-gray-700 dark:text-white;
}

.input-underline {
  @apply mt-0 block w-full border-0 border-b-2 border-gray-200 bg-transparent px-0.5 text-black outline-none focus:border-yellow-500 focus:ring-0 dark:text-white;
}

.input-error {
  @apply input-underline border-red-500 focus:border-red-500;
}

.bd-card {
  @apply flex h-full flex-col justify-center rounded-md bg-white px-6 py-4 text-black shadow-xl transition-colors dark:bg-gray-800 dark:text-white;
}

.bd-button {
  @apply rounded bg-yellow-500 text-white shadow-xl hover:bg-yellow-400 disabled:bg-gray-400 dark:disabled:bg-gray-500;
}

.bd-button-red {
  @apply rounded bg-red-500 text-white shadow-xl hover:bg-red-400 disabled:bg-gray-400 dark:disabled:bg-gray-500;
}

.switch-button {
  @apply m-2 rounded-lg bg-white px-3 py-1 text-black shadow-md hover:bg-yellow-400 hover:text-white disabled:cursor-default disabled:bg-yellow-500 disabled:text-white dark:bg-gray-500 dark:text-white dark:hover:bg-yellow-400 dark:disabled:bg-yellow-500 dark:disabled:text-white;
}

.page-container {
  @apply mb-16 mt-16 h-full w-full overflow-y-auto transition-colors md:ml-auto md:mb-0 md:w-10/12;
}

/**
  * Remove up/down arrows from number intput
**/

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
